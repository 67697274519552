<template>
  <div class="elite-tabs-wrapper-content">
    <div class="table-loading" slot="empty" v-if="templateModelUrl == ''">
     <img src="/img/loading.gif" />
    </div>
    <div id="template" v-if="templateModelUrl != ''">
      <iframe
        :src="templateModelUrl"
        type="application/pdf"
        width="100%"
        height="1000px"
      />
    </div>
    
  </div>
</template>
<script>

import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import moment from "moment";
export default {
  name: "preview-template",

  components: {},

  props: ["sections", "lang", "organizationId", "templateName"],

  mixins: [],

  data() {
    return {
      organization: null,
      loading: true,
      phones: [],
      phoneLabel : {
        fr : 'Téléphone',
        en : 'Phone'
      },
      fields: {},
      templateBlocs : [],
      details: {
        id: null,
        city: "",
        date: "",
        auction: "",
        locations: [],
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        lang: "fr",
        format: "pdf",
        onlyFirst: true,
        templateName : this.templateName
      },
      templateModelUrl: "",
    };
  },

  created() {
    //this.initPage();
    console.log(this.sections);
    this.templateBlocs = this.sections.reduce((result, template) => {
      result[template.block_name] = template.content_translate;
      return result;
    }, {});
    this.initPreview();
  },

  computed: {

  },

  methods: {
    async initPreview() {
      try {
        if (this.organizationId) {
          this.details.id = this.organizationId;
          this.details.lang = this.lang;
          this.details.templates = this.templateBlocs;
          const pdfResponse = await this.$store.dispatch(
            "organizations/getExamplePreview",
            this.details
          );
          const blob = new Blob([pdfResponse.data], { type: "application/pdf" });
          this.pdfUrl = URL.createObjectURL(blob);
          this.templateModelUrl = URL.createObjectURL(blob);
          /*console.log(`${process.env.VUE_APP_API_BASE_URL}/public/organizations/${this.details.id}/templates-preview/${this.details.lang}/${this.details.templateName}`);
          return `${process.env.VUE_APP_API_BASE_URL}/public/organizations/${this.details.id}/templates-preview/${this.details.lang}/${this.details.templateName}`;*/
        }

      } catch (error) {
        console.log(error);
      }
      
    },
  },

  mounted() {},

  watch: {},
};
</script>
<style scoped>
  .header-text{
    font-size: 10px;
    color: #000;
  }
  .argon-image{
    border: none;
  }
  .phone-number{
    display: block;
  }
  .text-body{
    font-size: 12px;
    margin-top: 30px;
  }
  .body-wrapper, .body-wrapper p{
    margin-top: 20px;
    font-size: 12px !important;
  }
  .fw-bold {
      font-weight: bold !important;
  }

  .f-underlined {
      text-decoration: underline !important;
      text-decoration-color: #000000 !important;
  }
</style>
