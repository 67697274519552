<template>
  <div>
    <form @submit.prevent="saveTemplate">
      <span class="resize-loading" v-if="!templates"></span>
      <div
        class="form-wrapper"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')}`"
          :placeholder="$t('COMMON.ORGANIZATION')"
        >
          <organization-selector
            :organization="selectedOrganization ? selectedOrganization : null"
            :filterable="true"
            :showAll="false"
            @organizationChanged="
              (organizationId) => {
                selectedOrganization = organizationId;
              }
            "
          />
        </base-input>
      </div>
      <!-- Onglets -->
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
        v-if="templates.length>0"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            Fr
          </span>
          <div class="row">
            <base-button
              type="button"
              class="btn btn-sm elite-submit preview-btn"
              @click="openViewModal();currentLang = 'fr';"
            >
              {{$t("COMMON.PREVIEW")}}
            </base-button>
            <base-button
              type="button"
              class="btn btn-sm elite-submit preview-btn"
              @click="openAddSectionModal();currentLang = 'fr';"
            >
              {{$t("ORGANIZATIONS.ADD_SECTION")}}
            </base-button>
            <div class="widget-collapse-list">
              <draggable
                class="widget-collapse-container"
                :list="templates"
                group="templates"
                @end="syncOrder"
              >
                <div class="widget-collapse mail-templates" v-for="template in templates" v-if="checkStartsWith(template.block_name)" :key="template.id">
                  <el-collapse accordion value="0">
                    <el-collapse-item class="personnalization" :name="template.id">
                      <template slot="title">
                        <base-button
                          type="button"
                          class="btn btn-sm elite-submit"
                          @click="removeBlockModal(template);"
                        >
                          <i class="fa fa-trash"></i>
                        </base-button>
                        <h3>{{ template.title }}</h3>
                      </template>
                      <div class="model-form-item">
                        <div class="model-form-item-input">
                          <base-input
                              :label="`${$t('COMMON.TITLE')}`"
                              :placeholder="$t('COMMON.TITLE')"
                              v-model="template.title"
                              @change="onFormChanged()"
                            />
                        </div>
                      </div>
                      <div class="model-form-item">
                        <div class="model-form-item-input">
                          <base-input
                            :label="$t('COMMON.CONTENT')"
                            :placeholder="$t('COMMON.CONTENT')"
                          >
                            <html-editor v-model="template.content_translate.fr"> </html-editor>
                          </base-input>
                        </div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </draggable>
            </div>
          </div>
        </tab-pane>
        <tab-pane title="global" id="2" :active="true">
          <span slot="title">
            En
          </span>
          <div class="row">
            <base-button
              type="button"
              class="btn btn-sm elite-submit preview-btn"
              @click="openViewModal();currentLang = 'en';"
            >
              {{$t("COMMON.PREVIEW")}}
            </base-button>
            <base-button
              type="button"
              class="btn btn-sm elite-submit preview-btn"
              @click="openAddSectionModal();currentLang = 'en';"
            >
              {{$t("ORGANIZATIONS.ADD_SECTION")}}
            </base-button>
            <div class="widget-collapse-list">
              <draggable
                class="widget-collapse-container"
                :list="templates"
                group="templates"
                @end="syncOrder"
              >
                <div class="widget-collapse mail-templates" v-for="template in templates" v-if="checkStartsWith(template.block_name)" :key="template.id">
                  <el-collapse accordion value="0">
                    <el-collapse-item class="personnalization" :name="template.id">
                      <template slot="title">
                        <base-button
                          type="button"
                          class="btn btn-sm elite-submit"
                          @click="removeBlockModal(template);"
                        >
                          <i class="fa fa-trash"></i>
                        </base-button>
                        <h3>{{template.title}}</h3>
                      </template>
                      <div class="model-form-item">
                        <div class="model-form-item-input">
                          <base-input
                              :label="`${$t('COMMON.TITLE')}`"
                              :placeholder="$t('COMMON.TITLE')"
                              v-model="template.title"
                              @change="onFormChanged()"
                            />
                        </div>
                      </div>
                      <div class="model-form-item">
                        <div class="model-form-item-input">
                          <base-input
                            :label="template.title"
                            :placeholder="$t('COMMON.NOTE')"
                          >
                            <html-editor v-model="template.content_translate.en"> </html-editor>
                          </base-input>
                        </div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </draggable>
            </div>
            <!--<div class="col-md-12" v-for="template in templates" :key="template.id">
              <div v-if="checkStartsWith(template.block_name)" class="form-wrapper full">
                <base-input
                  :label="labelNames(template.block_name)"
                  :placeholder="$t('COMMON.NOTE')"
                >
                  <html-editor v-model="template.content_translate.en"> </html-editor>
                </base-input>
              </div>
            </div>-->
          </div>
        </tab-pane>
      </tabs>
      <modal :show="showViewModal" modal-classes="modal-secondary" size="lg">
        <div class="modal-header">
          <button
              type="button"
              class="btn btn-link ml-auto"
              @click="closeViewModal"
            >
              <i class="fa fa-times"></i>
          </button>
        </div>
        <h3 class="text-center">
          {{ $t("COMMON.PREVIEW") }}
        </h3>
        

        <div>
          <preview-template v-if="showViewModal" :organizationId="organizationId" :sections="templates" :lang="currentLang" :templateName="name" />
        </div>
      </modal>
      <modal :show="showAddSectionModal" modal-classes="modal-secondary" size="lg">
        <div class="modal-header">
          <button
              type="button"
              class="btn btn-link ml-auto"
              @click="closeAddSectionModal"
            >
              <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="form-wrapper">
          <base-input
            :label="`${$t('COMMON.TITLE')}`"
            :placeholder="$t('COMMON.TITLE')"
            v-model="sectionTitle"
            @change="onFormChanged()"
          />
        </div>
        <div class="text-center btns-modal">
          <base-button
              @click="confirmAddSectionModal"
              type="button"
              class="elite-button btn-success text-right"
          >
            <span class="btn-inner--text">
              {{ $t("COMMON.ADD_ITEM") }}
            </span>
          </base-button>
        </div>
      </modal>
      <!--<ul class="nav nav-tabs" v-if="selectedOrganization">
        <li :class="{ active: activeTab === 'fr' }" @click="activeTab = 'fr'">
          <a href="#">Fr</a>
        </li>
        <li :class="{ active: activeTab === 'en' }" @click="activeTab = 'en'">
          <a href="#">En</a>
        </li>
      </ul>
      <div class="tab-content" v-if="selectedOrganization">
        <div v-if="activeTab === 'fr'" :class="{ active: activeTab === 'fr' }" class="tab-pane">
          <div class="row">
            <div class="col-md-12" v-for="template in templates" :key="template.id">
              <div v-if="checkStartsWith(template.block_name)" class="form-wrapper full">
                <base-input
                  :label="labelNames(template.block_name)"
                  :placeholder="$t('COMMON.NOTE')"
                >
                  <html-editor v-model="template.content_translate.fr"> </html-editor>
                </base-input>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 'en'" :class="{ active: activeTab === 'en' }" class="tab-pane">
          <div class="row">
            <div class="col-md-12" v-for="template in templates" :key="template.id">
              <div v-if="checkStartsWith(template.block_name)" class="form-wrapper full">
                <base-input
                  :label="labelNames(template.block_name)"
                  :placeholder="$t('COMMON.NOTE')"
                >
                  <html-editor v-model="template.content_translate.en"> </html-editor>
                </base-input>
              </div>
            </div>
          </div>
        </div>
      </div>-->
    </form>
  </div>
</template>

<script>
import swal from "sweetalert2";
import PreviewTemplate from "@/components/PreviewTemplate.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { Alert, Card, Collapse, CollapseItem } from "element-ui";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { Tabs, TabPane } from "@/components";
import draggable from 'vuedraggable';

export default {
  components: { 
    HtmlEditor, 
    Alert, [Card.name]: 
    Card, 
    [Alert.name]: Alert, 
    OrganizationSelector,
    Tabs,
    TabPane,
    PreviewTemplate, 
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    draggable,
  },
  props: {
    name: {
      type: String,
    },
  },
  data() {
    return {
      templates: [],
      user: {},
      selectedOrganization: "",
      organizationId: "",
      activeTab: 'fr',
      langs: ['fr', 'en'],
      frenchTemplates: [],
      englishTemplates: [],
      showViewModal: false,
      currentLang: 'fr',
      sectionTitle: '',
      showAddSectionModal: false,
    };
  },
  created() {
    this.activeTab = 'fr';
    this.user = this.$store.getters["profile/me"];
    if (this.user.organization) {
      this.organizationId = this.user.organization.id
      this.selectedOrganization = this.user.organization.id;
    } else {
      this.organizationId = this.selectedOrganization;
    }
  },
  mounted() {
    /*if (this.organizationId) {
      this.fetchTemplate();
    }*/
  },
  watch: {
    selectedOrganization(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.organizationId = newVal;
        this.fetchTemplate();
      }
    }
  },
  methods: {
    async removeBlockModal(block){
      const confirmation = await swal.fire({
        title: this.$t("ORGANIZATIONS.DELETE_THIS_TEMPLATE_BLOCK")+" "+block.title+"?",
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      if (confirmation.value === true) {
        this.templates = this.templates.filter(
          template => template.block_name !== block.block_name
        );
        console.log(this.templates);
      }
    },
    openAddSectionModal(){
      this.showAddSectionModal = true;
    },
    confirmAddSectionModal(){
      if(!this.sectionTitle){
        this.$notify({
          type: "danger",
          message: this.$t("COMMON.TITLE_REQUIRED"),
        });
        return;
      }
      let section = {
        title: this.sectionTitle,
        block_name : this.name+'_'+(this.templates.length + 1),
        content: '',
        content_translate: {fr : '', en : ''},
        position: null
      };
      this.templates.unshift(section);
      this.templates = this.templates.map((template, index) => ({
        ...template,
        position: index, // Génère un identifiant unique basé sur l'index
      }));
      this.showAddSectionModal = false;
    },
    closeAddSectionModal(){
      this.showAddSectionModal = false;
    },
    syncOrder(event) {
      this.templates = this.templates.map((template, index) => ({
        ...template,
        position: index, // Génère un identifiant unique basé sur l'index
      }));
    },
    openViewModal(){
      this.showViewModal = true;
    },
    closeViewModal(){
      this.showViewModal = false;
    },
    checkStartsWith(str) {
      return str.startsWith(this.name);
    },
    async fetchTemplate() {
      try {
        await this.$store.dispatch("organizations/getTemplates", this.organizationId);
        this.templates = await this.$store.getters["organizations/templates"];
        this.templates = this.templates.filter(template =>
          template.block_name.indexOf(this.name) !== -1
        );
        this.templates = this.templates.slice().sort((a, b) => a.position - b.position);
        this.templates.forEach((item) => {
          if(!item.title){
            item.title = this.$t(`ORGANIZATIONS.TEMPLATE_${item.block_name}`);
          }
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async saveTemplate() {
      try {
        this.templates = this.templates.map((template, index) => ({
          ...template,
          position: index, // Génère un identifiant unique basé sur l'index
        }));
        await this.$store.dispatch("organizations/saveTemplates", {
          id: this.organizationId,
          data : {
            templates : this.templates,
            templateName : this.name
          }
        });
        this.$notify({
          type: "success",
          message: "Modèle modifié avec succès",
        });
        
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    labelNames(name) {
      if (name === "BLOCK_LETTER_INTRO") {
        return "Lettre certifiée - Bloc Introduction";
      }
      if (name === "BLOCK_LETTER_SELL_NOTICE") {
        return "Lettre certifiée - Bloc Notification de vente";
      }
      if (name === "BLOCK_LETTER_PAYMENT_TERMS") {
        return "Lettre certifiée - Bloc Conditions de paiement";
      }
      if (name === "BLOCK_LETTER_OPEN_HOURS") {
        return "Lettre certifiée - Bloc Heures d'ouverture";
      }
      if (name === "BLOCK_LETTER_IMPORTANT_NOTE") {
        return "Lettre certifiée - Bloc Note importante";
      }
      if (name === "BLOCK_LETTER_SENDER") {
        return "Lettre certifiée - Bloc nom de l'expéditeur";
      }
      if (name === "BLOCK_LETTER_SENDERS_ROLE") {
        return "Lettre certifiée - Bloc Fonction de l'expéditeur";
      }
      if (name === "BLOCK_LETTER_AFTER_TAXES_FEES_TABLE") {
        return "Lettre certifiée - Bloc Après le tableau des taxes et frais";
      }
      if (name === "BLOCK_WEBSITE_ABOUT") {
        return "Site WEB - Bloc À propos de l'organisation";
      }
      if (name === "BLOCK_WEBSITE_SALE_CONDITIONS") {
        return "Site WEB - Bloc Règlement ou condition de vente";
      }
      if (name === "BLOCK_PUBLIC_NOTICE_HEADER") {
        return "Avis Public - Bloc en-tête";
      }
      if (name === "BLOCK_PUBLIC_NOTICE_FOOTER") {
        return "Avis Public - Bloc pied de page";
      }
      if (name === "BLOCK_UNSOLD_PROPERTIES_HEADER") {
        return "Propriétés non vendues - Bloc en-tête";
      }
      if (name === "BLOCK_UNSOLD_PROPERTIES_SIGNATURE") {
        return "Propriétés non vendues - Bloc signature";
      }
      if (name === "BLOCK_UNSOLD_PROPERTIES_SENDER") {
        return "Propriétés non vendues - Bloc nom de l'expéditeur";
      }
      if (name === "BLOCK_UNSOLD_PROPERTIES_SENDER_ROLE") {
        return "Propriétés non vendues - Bloc Fonction de l'expéditeur";
      }
      if(name === "BLOCK_CERTIFICATES_AWARDS_SUBJECT"){
        return "Certificat d'adjudication - Bloc Objet";
      }
      if(name === "BLOCK_CERTIFICATES_AWARDS_MESSAGE"){
        return "Certificat d'adjudication - Bloc Message";
      }
      if(name === "BLOCK_CERTIFICATES_AWARDS_SIGNATURE"){
        return "Certificat d'adjudication - Bloc Signature";
      }
      if(name === "BLOCK_CERTIFICATES_AWARDS_DELIVERED_AT"){
        return "Certificat d'adjudication - Bloc livraison";
      }
      if(name === "BLOCK_CERTIFICATES_AWARDS_DESCRIPTION_ADDRESS"){
        return "Certificat d'adjudication - Bloc description et adresse";
      }
      if(name === "BLOCK_CERTIFICATES_AWARDS_AMOUNT_TAXES_TOTAL"){
        return "Certificat d'adjudication - Bloc montant, taxes et total";
      }
      if(name === "BLOCK_CERTIFICATES_AWARDS_OWNER_INFOS"){
        return "Certificat d'adjudication - Bloc Infos proprétaire";
      }
    },
  },
};
</script>
<style>
.widget-collapse-container {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px 0;
}
.widget-collapse-list{
  display: block;
  width: 100%;
  margin-top: 20px;
}
  .preview-btn{
    color: #FFF !important;
    background-color: #707071 !important;
    font-size: 14px !important;
    margin-left: 16px !important;
  }
.quill .ql-container .ql-editor {
  border-radius: 0 0 8px 8px;
  color: #000;
}

.form-group {
  margin: 0;
}
/* Styles pour les onglets */
.nav-tabs {
  margin-bottom: 1rem;
  margin-top: 20px;
}

.nav-tabs li {
  display: inline-block;
  margin-right: 1rem;
  color: #ccc !important;
}

.nav-tabs li.active a {
  font-weight: bold;
  color: #2ba9e4 !important;
}

.tab-content {
  padding: 1rem;
  border: 1px solid #ddd;
  border-top: none;
}
.content-btns-modal{
    text-align: center !important;
  }
  .content-btns-modal a{
    margin-right: 20px;
  }
  .modal-header{
    margin-top: -43px;
    margin-right: -50px;
    margin-bottom: -30px;
  }
  .btns-modal{
    margin-top: 20px;
  }
</style>
